<template>
  <Multiselect
    v-model="model"
    :options="dropdownOptions"
    :hide-selected="hideSelected"
    :internal-search="false"
    id="ajax"
    :label="label"
    track-by="id"
    :preventAutofocus="false"
    :multiple="multiple"
    :placeholder="placeholder"
    @search-change="asyncFind"
  >
    <template #option="{ option }">
      <span>{{ option[label] }}</span>
      <small v-if="option.ticker" class="ml-2 text-gray-500"
        >({{ option.ticker }})</small
      >
    </template>
  </Multiselect>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const model = defineModel({
  type: [Object, Array],
  required: true,
});

const props = defineProps({
  source: {
    type: String,
    required: true,
    default: "users",
  },
  label: {
    type: String,
    default: "title",
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Select user",
  },
  hideSelected: {
    type: Boolean,
    default: true,
  },
});

const dropdownOptions = ref([]);
onMounted(async () => {
  try {
    const data = await useApiDropdownFetch(props.source);
    dropdownOptions.value = data.data;
  } catch (error) {
    console.error("Failed to load initial options:", error);
  }
});

async function asyncFind(query) {
  if (query.match(/^\s*$/)) {
    return;
  }

  try {
    const ajaxData = await useApiDropdownFetch(props.source, {
      query: {
        search: query,
      },
    });
    dropdownOptions.value = ajaxData.data;
  } catch (error) {
    console.error("Search failed:", error);
  }
}
</script>
